<template>
  <div class="live">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="live-main">
      <div class="search-box">
        <div class="search-item">
          <p>类型：</p>
          <div class="status">
            <div class="status-item" :class="item.id===searchForm.from_type?'status-active':''" v-for="item in liveRoomTypesList" :key="item.id" @click="changeTypes(item.id)">{{ item.label }}</div>
          </div>
        </div>
        <div class="search-item">
          <p>状态：</p>
          <div class="status">
            <div class="status-item" :class="item.id===searchForm.status?'status-active':''" v-for="item in liveRoomStatusList" :key="item.id" @click="changeStatus(item.id)">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <el-table :data="liveRoomList" border size="small" :header-cell-style="headerCellStyle">
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="直播名称" prop="name"></el-table-column>
        <el-table-column label="状态" width="100">
          <template v-slot='{row}'>
            {{ liveRoomStatusList?.find(item=>item.id===row.status)?.label }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="150">
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.created_at * 1000),"yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="开始时间" width="150">
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.start_time * 1000),"yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="结束时间" width="150">
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.end_time * 1000),"yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template v-slot='{row}'>
            <el-button type="primary" size="small" @click="enterLiveRoom(row)">进入直播间</el-button>
          </template>
        </el-table-column>
      </el-table>
      <MyPagin :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange"></MyPagin>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, getCurrentInstance } from 'vue';
  import util from "@/utils/tools.js";
  const instance=getCurrentInstance().proxy;


  // 分页器相关
  const pagination=reactive({
    page:1,
    pageSize:10,
    total:0
  });
  function currentChange(page){
    pagination.page=page;
    getLiveRoomList();
  }
  function sizeChange(size){
    pagination.pageSize=size;
    getLiveRoomList();
  }

  // 直播间状态列表
  const liveRoomStatusList=ref([]);
  function changeStatus(id){
    searchForm.status=id;
    getLiveRoomList();
  }
  // 直播间类型数据列表
  const liveRoomTypesList=ref([]);
  function changeTypes(id){
    searchForm.from_type=id;
    getLiveRoomList();
  }

  // 表单数据
  const searchForm=reactive({
    status:"-1",
    from_type:"-1" // 0课程直播,1活动直播,2考试监控
  });

  // 直播间列表
  const liveRoomList=ref([]);
  // 获取直播间列表
  function getLiveRoomList(){
    instance.api.live.liveRoomList({
      page:pagination.page,
      page_size:pagination.pageSize,
      user_id:"-1",
      ...searchForm
    }).then(res=>{
      pagination.total=Number(res.count);
      const arr=[];
      for(let key in res.statuses){
        arr.push({
          id:key,
          label:res.statuses[key]
        });
      }
      arr.unshift({id:"-1",label:"全部"});
      liveRoomStatusList.value=arr;

      const arr2=[];
      for(let key in res.types){
        arr2.push({
          id:key,
          label:res.types[key]
        });
      }
      arr2.unshift({id:"-1",label:"全部"});
      liveRoomTypesList.value=arr2;

      liveRoomList.value=res.list || [];
      console.log("直播间列表",res);
    });
  }
  getLiveRoomList();


  // 进入直播间
  function enterLiveRoom(row){
    if(row.from_type==="0"){
      instance.$router.push({
        path:"/liveroom-detail",
        query:{
          roomId:row.room_key || row.id,
          id:row.id,
          courseId:row.from_id
        }
      });
    }else if(row.from_type==="2"){
      instance.$router.push({
        path:"/trainingcamp/myexam",
      });
    }
  }


  const headerCellStyle={
    backgroundColor: "#F8F8F9",
    color: "#666",
    fontWeight: "bold"
  }

</script>

<style lang="scss" scoped>
  .live{
    padding: 20px;
    box-sizing: border-box;
    .live-main{
      padding: 20px;
      background-color: white;
      border-radius: 4px;
    }
    .search-box{
      margin-bottom: 20px;
      .search-item{
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        >p{
          font-weight: bold;
        }
        .status{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .status-item{
            margin-right: 5px;
            border-radius: 4px;
            padding: 5px 10px;

            cursor: pointer;
          }
          .status-active{
            color: white;
            background-color: #2d8cf0;
          }
        }
      }
    }
  }
</style>
